import {ENDPOINTS} from '~/constants';
import {userSchema} from '~/redux/users/entity-config';
import {TableState} from '~/types/general';
import {NormalizedUser, UserEntity} from '~/types/models/user';
import {thunk} from '~/utils/thunk';
import {DeleteParams, FetchParams, PostParams, PutParams} from 'redux-thunk-kit';

export interface FetchUsersParams extends FetchParams {
  search?: string;
}

export interface UpdateUserParams extends PutParams {
  id?: number;
  data: Partial<UserEntity>;
}

export interface AddUserParams extends PostParams {
  data: Partial<UserEntity> & {role_ids?: string[]};
}

export interface DeleteAccountParams extends DeleteParams {}

export interface AddTableStateParams extends PostParams {
  data: {
    tableName: string;
    stateName: string;
    state: TableState;
  };
}

export interface DeleteTableStateParams extends PostParams {
  data: {
    tableName: string;
    stateName: string;
  };
}

const query = thunk.query<UserEntity, NormalizedUser>('users', userSchema);

export const fetchUsers = query.fetch<FetchUsersParams>(ENDPOINTS.USERS);
export const addUser = query.post<AddUserParams>(ENDPOINTS.USERS);
export const updateUser = query.put<UpdateUserParams>(`${ENDPOINTS.USERS}/:id`);
export const deleteUser = query.delete<DeleteParams>(`${ENDPOINTS.USERS}/:id`);

export const fetchCurrentUserInfo = query.fetchOne<FetchUsersParams>(ENDPOINTS.CURRENT_USER, 'me/detail', {
  includeApiResponseData: true,
});

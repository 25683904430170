import React from 'react';
import {Global} from '@emotion/react';

function Fonts() {
  return (
    <Global
      styles={`
      @font-face {
        font-family: 'Inter';
        font-style: normal;
        font-weight: bold;
        src: url('/fonts/Inter-Bold.ttf') format('truetype');
        font-display: swap;
      } 
      @font-face {
        font-family: 'Inter';
        font-style: normal;
        font-weight: normal;
        src: url('/fonts/Inter.ttf') format('truetype');
        font-display: swap;
      }
      `}
    />
  );
}

export default Fonts;

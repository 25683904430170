import {BaseEntityConfig, WhiteListFields} from '~/redux/base-entity-config';
import {UserEntity} from '~/types/models/user';

class EntityConfig extends BaseEntityConfig<UserEntity> {
  entityKey = 'users';

  associates = [];

  whiteListFields: WhiteListFields<UserEntity> = ['*'];

  getAccessors = (value: UserEntity) => {};
}

const userEntConf = new EntityConfig();

export const userSchema = userEntConf.getEntitySchema();

export default userEntConf;

export const SOURCES_USER = {
  USERS_PAGE: 'USERS_PAGE',
  USERS_DROPDOWN: 'USERS_DROPDOWN',
};

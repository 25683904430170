import {UiText} from '~/components/elements';
import React from 'react';

export function AngryIcon({size = '25px', ...rest}: any) {
  return (
    <UiText fontSize={[size, size, size]} {...rest}>
      😡
    </UiText>
  );
}

import {BaseEntityConfig, WhiteListFields} from '~/redux/base-entity-config';
import {TranslatorEntity} from '~/types/models/translator';

class EntityConfig extends BaseEntityConfig<TranslatorEntity> {
  entityKey = 'translators';

  associates = [];

  whiteListFields: WhiteListFields<TranslatorEntity> = ['*'];

  getAccessors = (value: TranslatorEntity) => {
    //
  };
}

const translatorEntConf = new EntityConfig();

export const translatorSchema = translatorEntConf.getEntitySchema();

export default translatorEntConf;

export const SOURCES_TRANSLATOR = {
  TRANSLATORS_PAGE: 'TRANSLATORS_PAGE',
};

import React from 'react';
import {useHistory} from 'react-router-dom';
import {useAppDispatch, useInput, useNotification, useRequestState} from '~/hooks';
import {requestOK} from 'redux-thunk-kit';
import {
  AppMessage,
  Col,
  Row,
  UiBox,
  UiButton,
  UiCenter,
  UiDivider,
  UiHeading,
  UiImage,
  UiInput,
  UiText,
  UiVStack,
} from '~/components/elements';
import {appConfig} from '~/config';
import jwt_decode from 'jwt-decode';
import {loginByEmailMisc, socialLoginMisc} from '~/redux/auth/thunk';
import {getPageQuery} from '~/utils/helpers';
import {SOCIAL_LOGIN_PLATFORMS} from '~/constants';
import {GoogleCredentialResponse} from '@react-oauth/google';
import logo from '~/assets/img/logo.svg';
import {PageLayout} from '~/components/layouts/PageLayout';

function LoginPage() {
  const dispatch = useAppDispatch();
  const {addMessage} = useNotification(dispatch);
  const history = useHistory();
  const rules = {
    email: {email: true},
    password: {presence: true},
  };
  const [inputs, setInputs, {validation}] = useInput({email: '', password: '', activeTabIdx: 0}, {rules});

  const {loading} = useRequestState(loginByEmailMisc);

  const onLoginSuccess = (redirectAfterLogin?: string) => {
    // Try to redirect to the very first URL
    const urlParams = new URL(window.location.href);
    const queryParams = getPageQuery();
    let {redirect} = queryParams as {redirect: string};
    if (redirectAfterLogin) {
      history.replace(redirectAfterLogin);
      return;
    }
    if (redirect) {
      const redirectUrlParams = new URL(redirect);
      if (redirectUrlParams.origin === urlParams.origin) {
        redirect = redirect.substr(urlParams.origin.length);
        if (redirect.match(/^\/.*#/)) {
          redirect = redirect.substr(redirect.indexOf('#') + 1);
        }
      } else {
        window.location.href = '/';
        return;
      }
    }
    history.replace(redirect || '/');
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e?.preventDefault();
    if (validation.getErrors()) {
      return;
    }

    const resAction = await dispatch(loginByEmailMisc({data: {...inputs}}));
    if (requestOK(resAction, loginByEmailMisc)) {
      onLoginSuccess();
    }
  };

  const onLoginGoogleSuccess = async (resp: GoogleCredentialResponse) => {
    const {credential} = resp;

    const profileObj: any = jwt_decode(credential || '');

    const resAction = await dispatch(
      socialLoginMisc({
        data: {
          token: credential || '',
          provider: SOCIAL_LOGIN_PLATFORMS.GOOGLE,
          clientId: appConfig.googleClientId,
          profilePicture: profileObj.picture,
          name: profileObj.name,
        },
      }),
    );
    if (requestOK(resAction, socialLoginMisc)) {
      onLoginSuccess();
    }
  };

  // const onLoginGoogleFailure = useGoogleLogin({
  //   onError: errorResponse => addMessage(errorResponse.error_description || '', 'error'),
  // });

  const renderForm = () => (
    <UiBox mt={5}>
      <Col align="center">
        Google Login
        {/* <GoogleLogin */}
        {/*  hosted_domain="rayyone.com" */}
        {/*  width="auto" */}
        {/*  onSuccess={onLoginGoogleSuccess} */}
        {/*  onError={onLoginGoogleFailure} */}
        {/* /> */}
      </Col>
      <Row my={4} align="center">
        <UiDivider />
        <UiText fontWeight="normal" mx={3}>
          or
        </UiText>
        <UiDivider />
      </Row>

      <UiVStack spacing={4} mb={5}>
        <UiInput
          size="md"
          name="email"
          type="email"
          placeholder="Email"
          defaultValue={inputs.email}
          onChange={({target}) => setInputs({email: target.value})}
        />
        <UiInput
          size="md"
          name="password"
          type="password"
          placeholder="Password"
          defaultValue={inputs.password}
          onChange={({target}) => setInputs({password: target.value})}
        />
      </UiVStack>
      <Col align="flex-end">
        <UiButton w="160px" isLoading={loading} onClick={handleSubmit}>
          Sign in
        </UiButton>

        <UiButton type="submit" hidden />
      </Col>
    </UiBox>
  );

  return (
    <PageLayout metaTagsConfig={{metas: [{name: 'robots', content: 'noindex,nofollow'}]}}>
      <UiCenter>
        <UiBox width={['100%', '50%', '40%', '25%']} mt="100px">
          <Col align="center">
            <Row align="center">
              <UiImage src={logo} width="100px" mx="auto" mb={4} />
              <UiText fontSize="4xl" fontWeight="bold" color="secondary.600">
                Welcome
              </UiText>
            </Row>
          </Col>
          <UiBox borderWidth="1px" borderRadius="xl" p={6} boxShadow="lg" mx={2}>
            <UiHeading fontSize="md" textAlign="center" mb={3}>
              Login
            </UiHeading>
            <AppMessage body={validation.errors} />
            <form onSubmit={handleSubmit}>{renderForm()}</form>
          </UiBox>
        </UiBox>
      </UiCenter>
    </PageLayout>
  );
}

export default LoginPage;

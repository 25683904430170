import difference from 'lodash/difference';
import findKey from 'lodash/findKey';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import has from 'lodash/has';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isPlainObject from 'lodash/isPlainObject';
import isString from 'lodash/isString';
import set from 'lodash/set';
import {snakeCase} from '~/utils/helpers/string';
import {parse} from 'querystring';
import {AdminEntity, AdminRole} from '~/types/models/admin';
import {ROLE_ADMIN} from '~/constants';

export function snakeCaseObj(obj: Record<string, any>, removeWhitespace = true) {
  if (!isPlainObject(obj)) {
    return obj;
  }
  const res: Record<string, any> = {};
  Object.keys(obj).forEach(property => {
    let value = obj[property];
    if (isArray(value)) {
      value = value.map(item => snakeCaseObj(item));
    } else if (isPlainObject(value)) {
      value = snakeCaseObj(value);
    }
    if (removeWhitespace) {
      if (isString(value)) {
        value = value.replace(/\\t$/, '').trim();
      }
    }
    res[snakeCase(property)] = value;
  });

  return res;
}

export function parseFormDataToObj(formData: FormData) {
  const object: Record<string, any> = {};
  const formEntries = formData.entries();
  Object.keys(formEntries).forEach(key => {
    // @ts-ignore
    const value = formEntries[key];
    if (!(value instanceof File)) {
      object[key] = value;
    }
  });
  return object;
}

export function serializeQueryString(obj: Record<string, any>, {encode = true, snakeKey = false} = {}) {
  if (!obj) {
    return '';
  }
  return `?${Object.keys(obj)
    .reduce((a, k) => {
      if (Array.isArray(obj[k]) && obj[k].length > 0) {
        obj[k].forEach((val: any) => {
          const key = snakeKey ? snakeCase(k) : k;
          let value = val;
          if (isString(value)) {
            value = value.replace(/\\t$/, '').trim();
          }
          value = encode ? encodeURIComponent(value) : value;

          // @ts-ignore
          a.push(`${key}[]=${value}`);
        });
      } else if (obj[k] !== null && obj[k] !== undefined) {
        const key = snakeKey ? snakeCase(k) : k;
        let value = obj[k];
        if (isString(value)) {
          value = value.replace(/\\t$/, '').trim();
        }
        value = encode ? encodeURIComponent(value) : value;
        // @ts-ignore
        a.push(`${key}=${value}`);
      }
      return a;
    }, [])
    .join('&')}`;
}

export function findValuePath(obj: object, needle = 'default'): string[] {
  const matchKey = findKey(obj, v => v === needle);
  if (matchKey) {
    return [matchKey];
  }
  const res: string[] = [];
  forEach(obj, (v, k) => {
    if (typeof v === 'object') {
      const valuePath = findValuePath(v, needle);
      if (valuePath.length) {
        res.push(...(isArray(valuePath) ? valuePath : [valuePath]), k);
      }
    }
  });
  return res;
}

export function ensureSafeChaining(object: Object, path: string | string[], defaultValue?: any) {
  // Ensure the path is existed first
  if (!has(object, path)) {
    set(object, path, undefined);
  }
  // Set default value if you want to. (defaultValue !== undefined)
  if (defaultValue !== undefined && get(object, path) === undefined) {
    set(object, path, defaultValue);
  }
}

export function firstObjValue(obj: Record<any, any>) {
  // eslint-disable-next-line no-restricted-syntax
  for (const k in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(k)) {
      return obj[k];
    }
  }
  return undefined;
}

export function getObjNthItem(obj: Record<any, any>, nth: number) {
  if (!obj) {
    return undefined;
  }
  const index = nth - 1;
  const objKeys = Object.keys(obj);
  const targetKey = objKeys && objKeys[index];
  return obj[targetKey];
}

export const move = (array: any[], fromIndex: number, toIndex: number) => {
  const cloneArray = [...array];
  cloneArray.splice(toIndex, 0, cloneArray.splice(fromIndex, 1)[0]);
  return cloneArray;
};

export const getPageQuery = () => parse(window.location.href.split('?')[1]);

export const checkPermissions = (authorities: AdminRole[] = [], permissions: string[] = []) => {
  if (isEmpty(permissions)) {
    return true;
  }
  if (authorities.some(a => a.name === ROLE_ADMIN)) {
    return true;
  }
  const rolesCanNotAccess = difference(
    authorities.map(r => r.name),
    permissions,
  );
  return rolesCanNotAccess.length < authorities.length;
};

export const checkSuperAdmin = (user?: AdminEntity) => {
  if (!user) return false;
  return checkPermissions(user?.roles || [], [ROLE_ADMIN]);
};

export const randomColor = () => Math.floor(Math.random() * 16777215).toString(16);

export const isSubstringArrayInString = (substringArray: string[], string: string) =>
  substringArray.some(substring => string.indexOf(substring) > -1);

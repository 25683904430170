export const sharedStyle = {
  textarea: {
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '33px',
    paddingBottom: '20px',
    paddingInlineStart: 6, // space-6, equals to 20px
    paddingInlineEnd: 6,
  },
  borderColor: 'rgba(0, 0, 0, 0.15)',
};

import {PrivateLayout, PublicLayout} from '~/components/layouts';
import {BasicRoute, PrivateRoute} from '~/components/elements';
import HomePage from '~/pages/home-page/page/index';
import LoginPage from '~/pages/login';

export const publicRoutes: BasicRoute[] = [
  {
    path: '/',
    component: PublicLayout,
    exact: false,
    routes: [
      {path: '/', component: HomePage, exact: true},
      {path: '/login', component: LoginPage, exact: true, label: 'Login'},
    ],
  },
];

export const privateRoutes: PrivateRoute[] = [
  {
    path: '/app',
    component: PrivateLayout,
    exact: false,
    routes: [],
  },
];

import {Col, Row, UiBox, UiDivider, UiFlex, UiText} from '~/components/elements';
import {sharedStyle} from '~/theme/shared-style';
import {AngryIcon} from '~/pages/home-page/page/AngryIcon';
import {AngelIcon} from '~/pages/home-page/page/AngelIcon';
import React from 'react';

export function FameItem({inputText, translatedText}: any) {
  return (
    <UiFlex
      direction={{base: 'column', md: 'row'}}
      borderRadius={10}
      border="1px solid"
      borderColor={sharedStyle.borderColor}
      mt="15px"
      px="25px"
      pt="18px"
      pb="35px"
      bg="white"
    >
      <Col flex={{base: 0, md: 2}}>
        <Row>
          <AngryIcon />
          <UiText ml="17px" mt="3px">
            {inputText}
          </UiText>
        </Row>
      </Col>
      <UiBox mr={{base: 0, md: '13px'}} ml={{base: 0, md: '10px'}} my={{base: '10px', md: 0}}>
        <UiDivider orientation="vertical" borderColor={sharedStyle.borderColor} borderLeftWidth="0.5px" />
        <UiDivider orientation="horizontal" borderColor={sharedStyle.borderColor} borderLeftWidth="0.5px" />
      </UiBox>
      <Col flex={{base: 0, md: 5}}>
        <Row>
          <AngelIcon />
          <UiText ml="17px" mt="3px">
            {translatedText}
          </UiText>
        </Row>
      </Col>
    </UiFlex>
  );
}

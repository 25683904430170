import {ENDPOINTS} from '~/constants';
import {DeleteParams, FetchParams, PostParams, PutParams} from 'redux-thunk-kit';
import {TranslatorEntity, NormalizedTranslator} from '~/types/models/translator';
import {translatorSchema} from '~/redux/translators/entity-config';
import {thunk} from '~/utils/thunk';

export interface FetchTranslatorsParams extends FetchParams {}

export interface FetchTranslatorParams extends FetchParams {}

export interface AddTranslatorParams extends PostParams {
  data: Partial<TranslatorEntity>;
}

export interface UpdateTranslatorParams extends PutParams {
  data: Partial<TranslatorEntity>;
  id: string;
}

const query = thunk.query<TranslatorEntity, NormalizedTranslator>('translator', translatorSchema);
export const fetchTranslators = query.fetch<FetchTranslatorsParams>(ENDPOINTS.TRANSLATORS);
export const addTranslator = query.post<AddTranslatorParams, string>(ENDPOINTS.TRANSLATORS, '', {
  withoutNormalize: true,
});
export const updateTranslator = query.put<UpdateTranslatorParams>(`${ENDPOINTS.TRANSLATORS}/:id`);
export const deleteTranslator = query.delete<DeleteParams>(`${ENDPOINTS.TRANSLATORS}/:id`, '', {
  withoutNormalize: true,
});

export const fetchTranslator = query.fetchOne<FetchTranslatorParams>(`${ENDPOINTS.TRANSLATORS}/:id`);

import {
  Col,
  Row,
  UiBlackButton,
  UiBox,
  UiButton,
  UiStack,
  UiText,
  UiTextarea,
  useMediaQuery,
} from '~/components/elements';
import {QUERY_MOBILE} from '~/constants';
import {sharedStyle} from '~/theme/shared-style';
import React, {useState} from 'react';
import {AngelIcon} from '~/pages/home-page/page/AngelIcon';
import {AngryIcon} from '~/pages/home-page/page/AngryIcon';
import {useAppDispatch, useAppSelector, useInput, useRequestState} from '~/hooks';
import {addTranslator} from '~/redux/translators/thunk';
// eslint-disable-next-line no-restricted-imports
import {useToast} from '@chakra-ui/react';
import ReCAPTCHA from 'react-google-recaptcha';
import {appConfig} from '~/config';

export function PlayArea() {
  const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {ssr: false});
  const dispatch = useAppDispatch();
  const responseText = useAppSelector(state => state.translators.responseText);
  const [message, setMessage] = useState('');
  const [token, setToken] = useState('');

  const {loading} = useRequestState(addTranslator);
  const [isCopied, setIsCopied] = useState(false);
  const toast = useToast();
  const recaptchaRef = React.useRef();

  const onSubmit = async () => {
    await dispatch(addTranslator({data: {message, token}}));
    // @ts-ignore
    recaptchaRef.current.reset();
    setToken('');
  };

  const onCopy = () => {
    navigator.clipboard.writeText(responseText || '').then(() => {
      toast({
        title: 'Copied to clipboard',
        // description: "We've created your account for you.",
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 1500);
    });
  };

  return (
    <UiStack direction={{base: 'column', md: 'row'}} spacing="14px" mt={{base: '20px', md: 0}}>
      <Col flex={1} pos="relative" w="full">
        <UiBox pos="relative">
          <UiText textAlign="center" fontSize="16px" color="#1D2948" mb="9px">
            What’s actually on your mind...
          </UiText>
          <UiTextarea
            resize="none"
            height="389px"
            maxLength={150}
            placeholder="Type here..."
            bg="white"
            boxShadow="sm"
            value={message}
            onChange={({target}) => setMessage(target.value)}
          />
          <Row
            justifyContent="space-between"
            align="center"
            pl={sharedStyle.textarea.paddingLeft}
            pr={sharedStyle.textarea.paddingRight}
            pb="19px"
            pos="absolute"
            bottom={0}
            left={0}
            right={0}
            zIndex={1}
          >
            <UiText color="#595959">Max 150 chars</UiText>
            <Row align="center">
              <AngryIcon />
              <UiBlackButton
                title="Go!"
                w="110px"
                fontSize="14px"
                fontWeight="600"
                ml="5px"
                isLoading={loading}
                isDisabled={loading || !message || !token}
                onClick={onSubmit}
              />
            </Row>
          </Row>
        </UiBox>
        <UiBox mt="10px">
          <ReCAPTCHA
            // @ts-ignore
            ref={recaptchaRef}
            onChange={value => setToken(value!)}
            sitekey={appConfig.googleRecaptchaSiteKey}
          />
        </UiBox>
      </Col>
      <Col flex={1} pos="relative" w="full">
        <UiBox pos="relative">
          <UiText textAlign="center" fontSize="16px" color="#1D2948" mb="9px">
            What you’d wanna say is...
          </UiText>
          <UiBox
            overflow="auto"
            whiteSpace="pre-line"
            height="389px"
            bg="white"
            pl={sharedStyle.textarea.paddingLeft}
            pr={sharedStyle.textarea.paddingRight}
            pt={sharedStyle.textarea.paddingTop}
            pb={sharedStyle.textarea.paddingBottom}
            borderRadius="10px"
            border="1px solid"
            borderColor={sharedStyle.borderColor}
            boxShadow="sm"
          >
            <UiText>{responseText || ''}</UiText>
          </UiBox>
          <Row
            justifyContent="flex-end"
            align="center"
            pl={sharedStyle.textarea.paddingLeft}
            pr={sharedStyle.textarea.paddingRight}
            pb="19px"
            pos="absolute"
            bottom={0}
            left={0}
            right={0}
            zIndex={1}
          >
            <Row align="center">
              <AngelIcon />
              <UiButton
                bg="text.secondary"
                title="Copy"
                w="110px"
                fontSize="14px"
                fontWeight="600"
                ml="5px"
                isDisabled={!responseText}
                onClick={onCopy}
              />
            </Row>
          </Row>
        </UiBox>
      </Col>
    </UiStack>
  );
}

import {notify} from '~/redux/ui/slice';
import {AppDispatch} from '~/redux/root-store';

export function useNotification(dispatch: AppDispatch) {
  const addMessage = (message: string, type: 'error' | 'success' | undefined = undefined) => {
    if (type === 'error' && !message) {
      message = 'Something went wrong!';
    }
    dispatch(notify({message, type}));
    setTimeout(() => {
      dispatch(notify({type, message: ''}));
    }, 1000);
  };

  return {addMessage};
}

import {Button, ButtonProps} from '@chakra-ui/react';
import React, {memo} from 'react';
import {useDelay} from '~/hooks';

interface Props extends ButtonProps {
  title?: string;
}

const btnProps = {
  _hover: {backgroundColor: 'secondary.500', borderColor: 'secondary.800'},
  _active: {backgroundColor: 'secondary.500'},
};

export const UiButton = memo(
  React.forwardRef<HTMLButtonElement, ButtonProps>(({title, children, onClick, ...rest}: Props, ref) => {
    const bntTitle = title || children;
    const {startDelay, isDelaying} = useDelay(500);

    const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (onClick && !isDelaying) {
        onClick(e);
        startDelay();
      }
    };

    return (
      <Button ref={ref} onClick={handleClick} {...rest}>
        {bntTitle}
      </Button>
    );
  }),
);

export function UiBlackButton({bg = '#2C4142', ...rest}: ButtonProps) {
  return <UiButton variant="solid" bg={bg} {...rest} />;
}

export function UiPrimaryButton({bg = 'main.primary', ...rest}: ButtonProps) {
  return <UiButton variant="solid" color="black" bg={bg} {...rest} />;
}

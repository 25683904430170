import {theme} from '@chakra-ui/react';
import isArray from 'lodash/isArray';
import React, {memo, useEffect, useRef} from 'react';
import NotificationSystem from 'react-notification-system';
import {useAppSelector} from '~/hooks';

interface Props {
  children: React.ReactNode;
}

export const BaseLayout = memo(({children}: Props) => {
  const notificationSystem = useRef(null);
  const {error, success} = useAppSelector(state => state.ui.messages);

  useEffect(() => {
    if (error) {
      const notification = notificationSystem.current;
      if (notification) {
        if (isArray(error)) {
          // @ts-ignore
          notification.addNotification({
            title: 'Validation error!',
            level: 'error',
            position: 'tc',
            autoDismiss: 5,
            children: (
              <div>
                {error.map(e => (
                  <p key={e}>{e}</p>
                ))}
              </div>
            ),
          });
        } else {
          // @ts-ignore
          notification.addNotification({
            title: 'Oops, something went wrong!',
            message: error,
            level: 'error',
            position: 'tc',
            autoDismiss: 5,
          });
        }
      }
    }
  }, [error]);

  useEffect(() => {
    if (success) {
      const notification = notificationSystem.current;
      if (notification) {
        // @ts-ignore
        notification.addNotification({
          title: 'Success!',
          message: success,
          level: 'success',
          position: 'tc',
          autoDismiss: 5,
        });
      }
    }
  }, [success]);

  return (
    <div>
      <NotificationSystem ref={notificationSystem} style={notifOverrideStyle} />
      {children}
    </div>
  );
});

const notifOverrideStyle = {
  Containers: {
    DefaultStyle: {
      zIndex: theme.zIndices.tooltip,
    },
  },
};

import {Col, UiText} from '~/components/elements';
import {FameItem} from '~/pages/home-page/page/FameItem';
import React from 'react';

export function HallOfFame() {
  return (
    <Col mt="20px">
      <UiText textAlign="center" fontSize="16px" color="#1D2948" mb="10px">
        Angry email translator Hall of Fame
      </UiText>
      <FameItem
        inputText="Fuck off, this idea is stupid as fuck."
        translatedText={
          <UiText fontSize="14px">
            Dear [Recipient],
            <br />
            <br />
            I hope this email finds you well. I wanted to express my concerns regarding the idea that was presented. I
            believe that there may be some potential issues that need to be addressed before moving forward. Would it be
            possible to discuss this further and explore alternative solutions?
            <br />
            <br />
            Thank you for your time and consideration.
            <br />
            <br />
            Best regards,
            <br />
            [Your Name]
          </UiText>
        }
      />
      <FameItem
        inputText="I think your offer is the stupidest offer I've ever heard of"
        translatedText={
          <UiText fontSize="14px">
            Dear [Recipient],
            <br />
            <br />
            I hope this email finds you well. I wanted to express my concerns regarding the offer that was presented to
            me. While I appreciate the effort put into it, I believe that it may not be the best fit for my current
            needs. I would appreciate it if we could discuss this further and explore other options that may be more
            suitable.
            <br />
            <br />
            Thank you for your time and consideration.
            <br />
            <br />
            Best regards,
            <br />
            [Your Name]
          </UiText>
        }
      />
      <FameItem
        inputText="Carl, last meeting was a fucking waste of time, no way I’m doing this again. Email me, or fuck off. "
        translatedText={
          <UiText fontSize="14px">
            Dear Carl,
            <br />
            <br />
            I hope this email finds you well. I wanted to follow up on our last meeting and express my concerns
            regarding its effectiveness. I feel that the meeting did not meet expectations and did not provide us with
            the necessary outcomes.
            <br />
            <br />
            In the future, I would appreciate it if we could communicate via email to ensure that our time is used
            efficiently. I understand that we all have busy schedules, and I want to make sure that we are making the
            most of our time together.
            <br />
            <br />
            Thank you for your understanding, and I look forward to working with you in the future.
            <br />
            <br />
            Best regards,
            <br />
            [Your Name]
          </UiText>
        }
      />
    </Col>
  );
}

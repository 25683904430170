import React, {memo} from 'react';
import {BaseLayout} from '~/components/layouts';
import {UiBox} from '~/components/elements';

// import ReactGA from 'react-ga';

interface Props {
  children: React.ReactNode;
}

export const PublicLayout = memo(({children}: Props) => (
  // if (isEmpty(currentUser) && window.location.pathname !== '/p/login') {
  //   const redirectTo = stringify({
  //     redirect: window.location.href,
  //   });
  //   return <Redirect to={`/p/login?${redirectTo}`} />;
  // }
  //
  // const {pathname} = useLocation();
  // useEffect(() => {
  //   ReactGA.pageview(pathname);
  // }, [pathname]);

  <BaseLayout>
    {/* <Navigation /> */}
    <UiBox minH="100vh">{children}</UiBox>
    {/* <Footer /> */}
  </BaseLayout>
));

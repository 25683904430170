import {
  Colors,
  extendTheme,
  ThemeComponents,
  ThemeConfig,
  withDefaultColorScheme,
  withDefaultSize,
} from '@chakra-ui/react';
import {sharedStyle} from '~/theme/shared-style';

const fonts = {
  heading: 'Inter',
  body: 'Inter',
};
const colors: Colors = {
  // Base color: #2C4142. Generated by https://smart-swatch.netlify.app
  main: {
    50: '#E6FFFA',
    100: '#B2F5EA',
    200: '#81E6D9',
    300: '#4FD1C5',
    400: '#38B2AC',
    500: '#319795',
    600: '#2C7A7B',
    700: '#285E61',
    800: '#234E52',
    900: '#1D4044',
    primary: '#E2EEF3',
    border: '#D3D3D3',
    background: '#F2F2F0',
  },
  text: {
    primary: '#020202',
    secondary: '#37858A',
  },
  black: {
    100: '#575757',
    400: '#305853',
  },
  link: {
    active: '#975ca5',
  },
  secondary: {
    50: '#daffff',
    100: '#afffff',
    200: '#80ffff',
    300: '#52ffff',
    400: '#31fefe',
    500: '#24e4e5',
    600: '#12b2b3',
    700: '#007f80',
    800: '#004c4e',
    900: '#001b1c',
  },
};
const fontSizes = {
  xs: '0.6rem',
  sm: '0.7rem',
  md: '0.8rem',
  lg: '0.94rem',
  xl: '1.4rem',
  '2xl': '1.6rem',
  '3xl': '1.9rem',
  '4xl': '2.2rem',
  '5xl': '2.8rem',
  '6xl': '3.4rem',
  '7xl': '4rem',
  '8xl': '5.2rem',
  '9xl': '6.8rem',
};
const sizes = {
  full: '100%',
  '3xs': '10.2rem',
  '2xs': '12.8rem',
  xs: '15rem',
  sm: '17rem',
  md: '20.4rem',
  lg: '23.6rem',
  xl: '25.8rem',
  '1': '0.2rem',
  '2': '0.4rem',
  '3': '0.6rem',
  '4': '0.8rem',
  '5': '1rem',
  '6': '1.25rem',
  '7': '1.5rem',
  '8': '1.75rem',
  '9': '2rem',
  '10': '2.25rem',
  '12': '2.5rem',
  '14': '3rem',
  '16': '3.5rem',
  '20': '4rem',
  '24': '5rem',
  '28': '6rem',
  '32': '7rem',
  '36': '8rem',
  '40': '9rem',
  '44': '10rem',
  '48': '11rem',
  '52': '12rem',
  '56': '13rem',
  '60': '14rem',
  '64': '15rem',
  '72': '16rem',
  '80': '18rem',
  '96': '20rem',
  px: '24rem',
  '0.5': '1px',
  '1.5': '0.125rem',
  '2.5': '0.375rem',
  '3.5': '0.625rem',
  '2xl': '25.8rem',
  '3xl': '30.6rem',
  '4xl': '35.4rem',
  '5xl': '40.8rem',
  '6xl': '45.2rem',
  '7xl': '50.6rem',
  '8xl': '80rem',
};
const spacing = {
  space: {
    px: '1px',
    0.5: '0.125rem',
    1: '0.25rem',
    1.5: '0.3rem',
    2: '0.37rem',
    2.5: '0.4rem',
    3: '0.5rem',
    3.5: '0.7rem',
    4: '0.8rem',
    5: '0.9rem',
    6: '1.25rem',
    7: '1.5rem',
    8: '1.75rem',
    9: '2rem',
    10: '2.25rem',
    12: '2.5rem',
    14: '3rem',
    16: '3.5rem',
    20: '4rem',
    24: '5rem',
    28: '6rem',
    32: '7rem',
    36: '8rem',
    40: '9rem',
    44: '10rem',
    48: '11rem',
    52: '12rem',
    56: '13rem',
    60: '14rem',
    64: '15rem',
    72: '16rem',
    80: '17rem',
    96: '19rem',
  },
};

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

const shadows = {
  outline: '0 0 0 3px #49696b',
};

const components: ThemeComponents = {
  Button: {
    baseStyle: {
      borderRadius: 10,
    },
    sizes: {
      sm: {
        minW: '40px',
      },
    },
  },
  Link: {
    baseStyle: {
      textDecoration: 'none',
    },
  },
  FormLabel: {
    baseStyle: {
      fontSize: 'sm',
    },
  },
  Text: {
    baseStyle: {
      fontSize: '0.875rem',
    },
  },
  Input: {
    baseStyle: {
      field: {
        color: 'teal.500', // change the input text color
      },
    },
    variants: {
      outline: {
        field: {
          border: '1px solid',
          borderColor: 'teal.500',
          _hover: {
            borderColor: 'teal.600',
          },
          _focus: {
            borderColor: 'teal.700',
          },
        },
      },
    },
  },
  Textarea: {
    baseStyle: {
      color: '#000000', // change the input text color
      paddingTop: sharedStyle.textarea.paddingTop,
      paddingBottom: sharedStyle.textarea.paddingBottom,
    },
    variants: {
      outline: {
        border: '1px solid',
        borderColor: sharedStyle.borderColor,
        borderRadius: '10px',
        fontSize: '14px',
        paddingInlineStart: sharedStyle.textarea.paddingInlineStart,
        paddingInlineEnd: sharedStyle.textarea.paddingInlineEnd,
        _hover: {
          borderColor: 'teal.500',
        },
        _focus: {
          borderColor: 'teal.600',
          boxShadow: 'none',
        },
      },
    },
  },
};

export const uiTheme = extendTheme(
  {colors, config, components, shadows, fonts, fontSizes, sizes, ...spacing},
  withDefaultColorScheme({colorScheme: 'main'}),
  withDefaultSize({size: 'sm'}),
);

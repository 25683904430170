// import RNFetchBlob from 'rn-fetch-blob';
import {thunk} from '~/utils/thunk';
import {FetchParams, PostParams, PutParams, RejectErrorValue} from 'redux-thunk-kit';
import {ENDPOINTS} from '~/constants';
import {AppDispatch} from '~/redux/root-store';
import {createAsyncThunk} from '@reduxjs/toolkit';
import request from '~/utils/request';
import {AppError} from 'ry-error-wrapper';
import {GetSignedUrlS3ApiItem, UploadImageModules} from '~/types/models/app';

export interface GetSignedUrlS3Params extends FetchParams {
  file: string;
}

export interface FetchAppsParams extends FetchParams {}

export interface UploadFileToServer extends PostParams {
  data: {
    module: UploadImageModules;
    fileName: string;
    filePath: string;
  };
}

export interface UpdateAppParams extends PutParams {
  data: {};
  id: number;
}

const query = thunk.query('app');

export const uploadFile = createAsyncThunk<
  {accessUrl: string; fileExt: string},
  {module: string; fileName: string; file: File},
  {dispatch: AppDispatch; rejectValue: RejectErrorValue}
>('file/upload', async ({module, fileName, file}, {rejectWithValue}) => {
  try {
    const res = await request.post(ENDPOINTS.FILE_UPLOAD, {module, file_name: fileName});
    // @ts-ignore
    const {presign_url: presignUrl, access_url: accessUrl, file_ext: fileExt} = res.data.data;

    const data = new FormData();
    data.append('file', file, fileName);
    await request.put(presignUrl, file, {
      headers: {'Content-Type': file.type, 'X-Amz-Acl': 'public-read'},
      timeout: 60000,
    });
    return {accessUrl, fileExt};
  } catch (e) {
    new AppError(e);
    return rejectWithValue({errMsg: 'Can not upload file!'} as RejectErrorValue);
  }
});

export const getSignedUrlS3 = query.fetch<GetSignedUrlS3Params, GetSignedUrlS3ApiItem>(
  `${ENDPOINTS.FILE_TEMPORARY_URL}`,
  'getSignedUrlS3',
  {
    withoutNormalize: true,
  },
);

// export const uploadFileToServer = query.wrapper<UploadFileToServer, UploadFileToServerApiItem>(
//   'upload',
//   async ({data: {fileName, filePath, module}}, dispatch, onError, getState) => {
//     try {
//       const resp = await RNFetchBlob.fetch(
//         'POST',
//         appConfig.api.url + ENDPOINTS.UPLOAD_FILE,
//         {
//           Authorization: `Bearer ${getState().auth?.token}`,
//           // this is required, otherwise it won't be process as a multipart/form-data request
//           'Content-Type': 'multipart/form-data',
//         },
//         [
//           {
//             name: 'file',
//             filename: fileName,
//             data: RNFetchBlob.wrap(decodeURIComponent(filePath.replace('file://', ''))),
//           },
//           {name: 'module', data: module},
//         ],
//       );
//       return resp?.json()?.data || ({} as UploadFileToServerApiItem);
//     } catch (e) {
//       new AppError(e, '', {
//         debugMessage: `Upload photo failed!:
//          If you got "SyntaxError: JSON Parse error: Unexpected token: <", usually it's because of: 413 Entity too large`,
//       });
//       onError(e);
//     }
//     return {} as UploadFileToServerApiItem;
//   },
// );
// export const fetchApps = query.fetch<FetchAppsParams, AppApiItem[]>(ENDPOINTS.APPS, '', {
//   withoutNormalize: true,
// });
// export const updateApp = query.put<UpdateAppParams, AppApiItem>(`${ENDPOINTS.APPS}/:id`, '', {
//   withoutNormalize: true,
// });
// export const deleteApp = query.delete<DeleteParams, AppApiItem>(`${ENDPOINTS.APPS}/:id`, '', {
//   withoutNormalize: true,
// });
//

import {createEntityAdapter, createSlice, EntityId} from '@reduxjs/toolkit';
import mapValues from 'lodash/mapValues';
import {TranslatorEntity} from '~/types/models/translator';
import {RootState} from '~/redux/root-reducer';
import {
  addIdsToSources,
  createSelectAllBySource,
  DEFAULT_SOURCE_REDUCER_STATE,
  deleteSuccess,
  fetchSuccess,
  ReducerState,
  upsertMany,
  upsertManyMutably,
  upsertOneMutably,
} from 'redux-thunk-kit';
import {SOURCES_TRANSLATOR} from '~/redux/translators/entity-config';
import {addTranslator, deleteTranslator, fetchTranslators, updateTranslator} from '~/redux/translators/thunk';

interface InitialState extends ReducerState {
  responseText?: string;
}

const sources = mapValues(SOURCES_TRANSLATOR, () => DEFAULT_SOURCE_REDUCER_STATE);

const translatorsAdapter = createEntityAdapter<TranslatorEntity>();

const initialState = translatorsAdapter.getInitialState<InitialState>({sources});

const translators = createSlice({
  name: 'translators',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchTranslators.fulfilled, (state, {payload}) => {
      upsertManyMutably(translatorsAdapter, state, payload.normalized.entities.translators);
      fetchSuccess(state, payload);
    });
    builder.addCase(addTranslator.fulfilled, (state, {payload}) => {
      state.responseText = payload;
    });
    builder.addCase(updateTranslator.fulfilled, (state, {payload}) => {
      upsertOneMutably(translatorsAdapter, state, payload.normalized.entities.translators);
    });
    builder.addCase(deleteTranslator.fulfilled, (state, {payload, meta}) => {
      const {arg} = meta;
      translatorsAdapter.removeOne(state, arg?.id as EntityId);
      deleteSuccess(state, arg?.id);
    });
  },
});

// export const {} = translators.actions;

export default translators.reducer;

export const {
  selectById: selectTranslatorById,
  selectIds: selectTranslatorsIds,
  selectEntities: selectTranslatorsEntities,
  selectAll: selectAllTranslators,
  selectTotal: selectTotalTranslators,
} = translatorsAdapter.getSelectors((state: RootState) => state.translators);

export const [getTranslators] = createSelectAllBySource('translators', selectTranslatorsEntities, [
  SOURCES_TRANSLATOR.TRANSLATORS_PAGE,
]);

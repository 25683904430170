import store from 'store';
// @ts-ignore
import cookieStorage from 'store/storages/cookieStorage';

const ck = store.createStore([cookieStorage]);
const storages: Record<string, typeof store> = {
  default: store,
  cookie: ck,
};

type StorageType = 'default' | 'cookie';

export function loadString(key: string, type: StorageType = 'default') {
  try {
    return storages[type].get(key);
  } catch {
    return null;
  }
}

export function saveString(key: string, value: string, type: StorageType = 'default') {
  try {
    return storages[type].set(key, value);
  } catch {
    return false;
  }
}

export function load(key: string, type: StorageType = 'default') {
  try {
    return storages[type].get(key) || '';
  } catch {
    return null;
  }
}

export function save(key: string, value: any, type: StorageType = 'default') {
  try {
    return storages[type].set(key, value);
  } catch (e) {
    return false;
  }
}

export function remove(key: string, type: StorageType = 'default') {
  return storages[type].remove(key);
}

/**
 * Burn it all to the ground.
 */
export function clear(type: StorageType = 'default') {
  return storages[type].clearAll();
}

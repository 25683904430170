import {UiText} from '~/components/elements';
import React from 'react';

export function HeartIcon({size = '25px', ...rest}: any) {
  return (
    <UiText fontSize={[size, size, size]} {...rest}>
      ❤️
    </UiText>
  );
}

import {appConfig} from '~/config';
import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';

export const initSentry = async () => {
  if (appConfig.sentry.isEnable) {
    Sentry.init({
      dsn: appConfig.sentry.dsn,
      integrations: [new Integrations.BrowserTracing()],

      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 1.0,
    });
  }
};

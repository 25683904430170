import React, {memo} from 'react';
import {UiCenter, UiSpinner} from '.';

interface Props {
  text?: string;
}

export const UiPageLoading = memo(({text = 'Loading'}: Props) => (
  <UiCenter className="h-screen">
    <UiSpinner size="xl" label={text} />
  </UiCenter>
));

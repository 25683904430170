export const SOCIAL_LOGIN_PLATFORMS = {
  GOOGLE: 'GOOGLE',
};
export const ERROR_STATUS_CODE = {
  EMAIL_VERIFIED_ERROR: 'alg_wc_ev_email_verified_error',
  INCORRECT_PASSWORD: 'incorrect_password',
  INVALID_EMAIL: 'invalid_email',
};

export const PAGES = {
  CONTACT_US: '/contact',
  STORIES: '/stories',
  CONTACT_SUBMITTED: '/contact-submitted',
  UNSUBSCRIBE: '/unsubscribe',
  POLICY: '/policy',
};

export const QUERY_MOBILE = '62em';

import React from 'react';
import {Col, Row, UiButton, UiIcon, UiText, UiTitle, useMediaQuery} from '~/components/elements';
import {PageLayout} from '~/components/layouts/PageLayout';
import {appConfig} from '~/config';
import {QUERY_MOBILE} from '~/constants';
import {PlayArea} from '~/pages/home-page/page/PlayArea';
import {HeartIcon} from '~/pages/home-page/page/HeartIcon';
import {HallOfFame} from '~/pages/home-page/page/HallOfFame';
import {BiDonateHeart} from 'react-icons/bi';

function Title() {
  return (
    <Col align="center">
      <UiTitle fontSize={{base: '20px', lg: '25px'}}>🔥 Angry email translator 🔥</UiTitle>
      <UiText fontSize={{base: '16px', lg: '16px'}} color="text.secondary" my="10px">
        Go wild, AI will make it work-appropriate
      </UiText>
    </Col>
  );
}

function SidebarAd({side, ...rest}: any) {
  return (
    <Col w="100%" align={side} hideBelow="md" {...rest}>
      {/* <Col mx="27px" border="0.5px solid black" h="489px" w="171px" mt="230px"> */}
      {/*  Ad */}
      {/* </Col> */}
    </Col>
  );
}

function HomePage() {
  const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {ssr: false});

  return (
    <PageLayout
      metaTagsConfig={{
        title: 'Angry Email Translator - Translate angry emails into polite ones!',
        description:
          'Angry Email Translator is a free online tool that uses AI to help you translate your angry, paggro emails into polite and professional sounding ones. Our AI-powered tool makes your emails more professional and avoids damaging relationships. Try it now!',
        metas: [
          {
            name: 'keywords',
            content:
              'email, email translator, translate, AI-powered, professional, free tool, email generator, AI email, ChatGPT, AI',
          },
        ],
      }}
      bg="main.background"
    >
      <Row minH="100vh" px={{base: '10px', md: 0}}>
        <SidebarAd side="flex-end" />
        <Col maxW="768px" h="100%" pb="115px" pt={{base: '20px', lg: '100px'}}>
          <Title />
          <Col w="100%">
            <PlayArea />
            <Col align="center" my="10px">
              <UiText color="text.secondary" fontSize="16px" mb="8px">
                www.angryemailtranslator.com
              </UiText>
              <UiText color="text.secondary" fontSize="16px">
                Love this product?&nbsp;
                <HeartIcon size="16px" color="red" as="span" />
                &nbsp;Share it with everyone you know!
              </UiText>
            </Col>

            {/* <Col border="0.5px solid black" h="90px" w="100%" my="20px"> */}
            {/*  Ad */}
            {/* </Col> */}

            <UiText mx={{base: '10px', md: '25px'}} textAlign="center" fontSize="12px" color="#494949">
              We made this fun tool free but we actually incur running costs due to the compute needed for AI outputs.
              If you love this product, please share it with your friends, or even better, make a small donation here
            </UiText>
{/*             <UiButton
              onClick={() => {
                window.open('https://buy.stripe.com/5kAfZs2Sxer0cve144', '_blank');
              }}
              mt="10px"
              alignSelf="center"
              w="100px"
              leftIcon={<UiIcon as={BiDonateHeart} color="white" />}
              colorScheme="red"
            >
              Donate
            </UiButton> */}
            <HallOfFame />
          </Col>
        </Col>
        <SidebarAd side="flex-start" />
      </Row>
    </PageLayout>
  );
}

export default HomePage;
